import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { swipeableList } from '../model/action/list';
import DialogsMessages from '../../Dialogs/components/DialogsMessages'; 

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';

// Import a language file.
import 'froala-editor/js/languages/ru.js';

// Define the custom button

export default function ChatDrawer(props) {
    const { dialogs_messages, setDialogsMessages } = props;
    const dispatch = useDispatch();
    const [content, setContent] = useState('');
    const editorRef = useRef(null);

    const handleModelChange = (event) => {
        setContent(event);
    };
    
    const handleSendGpt4Free = () => {
        // event.preventDefault();
        if (content.trim() !== "") {
            const newMessage = {
                role: "user",
                content: content
            };
            setDialogsMessages(prevMessages => [...prevMessages, newMessage]);
            setContent("");
            if (editorRef.current) {
                editorRef.current.editor.html.set('');
            }

            let data = JSON.stringify({
                "model": "gpt-4o-free",
                "provider": "Liaobots",
                "stream": false,
                "messages": [...dialogs_messages, newMessage]
            });

            dispatch(swipeableList(data)).then((result) => {
                const completionMessage = {
                    role: "assistant",
                    content: result.payload
                };
                setDialogsMessages(prevMessages => [...prevMessages, completionMessage]);
            }).catch((error) => {
                console.error('Operation failed:', error);
            });
        }
    };

    return (
        <div className="chat-drawer">
            <h5 className="title pt-3 mb-3" style={{ display: "flex", alignItems: "center" }}>
                {/* <i className="icon-pages feather-message-square"></i> */}
                Чат с нейросетью
            </h5>
            <div className="chat-content">
                <div className="message-list">
                    {dialogs_messages && dialogs_messages.map((item, index) => (
                        <DialogsMessages key={index} messages={item} />
                    ))}
                </div>
            </div>
            <div className="chat-bottom">
                <div className="chat-form">
                    <div>
                        <textarea className="form-control" placeholder={"Введите сообщение..."}  row="8" onChange={(e) => handleModelChange(e.target.value)} value={content} style={{
                            borderRadius: "15px",
                            width: "100%",
                            padding: "14px 18px",
                            position: "relative",
                            height: "104px",
                            fontSize: "18px",
                            "&:active, &:focus": {
                                mb: "30vh"
                            }
                        }}></textarea>
                        {/* <FroalaEditorComponent
                            ref={editorRef}
                            tag='textarea'
                            config={{
                                placeholder: "Как вам помочь?",
                                heightMin: 180,
                                charCounterCount: false,
                                spellcheck: true,
                                quickInsertEnabled: false,
                                language: 'ru',
                                toolbarButtons: {
                                    'moreMisc': {
                                        'buttons': [
                                            'undo', 'redo'
                                        ],
                                        'align': 'left',
                                        'buttonsVisible': 2
                                    },
                                    'moreText': {
                                        'buttons': [
                                            'bold', 'italic', 'underline'
                                        ]
                                    },
                                },
                            }}
                            onModelChange={handleModelChange}
                        /> */}
                        <div className="send-btn" style={{
                            position: "absolute",
                            right: "30px",
                            top: "60px"
                        }}><button type="button" onClick={()=>handleSendGpt4Free()}><i className="feather-send"></i></button></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
